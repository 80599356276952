import React from "react";
import ReactDOM from "react-dom/client";
import PollPages from './PollPages';

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <PollPages />
);
