import { Box } from "@mui/material";
import { Outlet,useNavigate } from "react-router-dom";
import SideDrawer from "./components/Drawer/SideDrawer";
import { useLocation } from 'react-router-dom';
import { Button } from '@mui/material';

function App() {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <div className="App">
      <Box sx={{ display: "flex" }}>
       {location.pathname !== '/login' && <SideDrawer />} 
        <Outlet />
        {location.pathname !== '/login' && <Button
                size="large"
                variant="contained"
                style={{ height: '40px',  marginTop: '20px', marginLeft: '22%' }}
                onClick={() => {
                  window.localStorage.removeItem("username");
                  window.localStorage.removeItem("apikey");
                  navigate('/login');
                }}
              >
                Logout
              </Button> }
      </Box>
    </div>
  );
}

export default App;
