export const getTranslationArray = (data: any) => {
  let result = Object.entries(data)
    .filter(([k, v]) => {
      return v !== "";
    })
    .map(([k, v]) => ({
      languageCode: k,
      content: v,
    }));
  return result;
};

export const getObjectProperty = (key: any, data: any) => {
  type ObjectKey = keyof typeof data;
  const result = key as ObjectKey;
  return data[result] ? data[result] : "";
};

export const setObjectProperty = (key: any, value: any, data: any) => {
  type ObjectKey = keyof typeof data;
  const result = key as ObjectKey;
  data[result] = value;
  return data;
};

export const YYYYMMDDtoDate = (date: any) => {
  const updatedDate = date.split("T",1);
  const [year, month, day] = updatedDate[0].split("-");
  const updatedTime = date.split("T",2);
  const [hour, minute, second] = updatedTime[1].split(":");
  const result = new Date(+year, month - 1, +day, hour, minute, second);
  return result;
};

export const utcToIst = (currDate: string) => {
  var date= new Date(currDate);
  date = new Date(date.getTime() - date.getTimezoneOffset()*60*1000);
  const localDate= date.getFullYear() + "-" + (date.getMonth()+1) + "-" + date.getDate() + "T" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  return localDate;
}
