export const defaultLanguageList = {
    en: "",//english
    bn: "",//bengali
    gu: "",//gujarathi
    hi: "",//hindi
    hr: "",//bhojpuri
    kn: "",//kannada
    ml: "",//malayalam
    mr: "",//marathi
    pa: "",//punjabi
    ta: "",//tamil
    te: ""//telugu
  };
