import React, { useEffect, useState } from "react";
import { Box, Typography, Snackbar, TableCell, TableBody, TableRow } from "@mui/material";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Link,useNavigate } from "react-router-dom";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { deleteCall, get } from "../../utils/FetchMethods";
import { DEFAULT_URL } from "../../utils/Constants";
import { YYYYMMDDtoDate, utcToIst } from "../../utils/CommonUtils";

const Home = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [messageState, setMessageState] = useState<String | null>(null);
  const [activePoll, setActivePoll] = useState([]);
  const [expirePoll, setExpirePoll] = useState([]);
  const navigate = useNavigate();
  let active: any = [];
  let expire: any = [];

  const tabChange = (event: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  const deletepoll = (id: String) => {
    deleteCall(new URL(`${DEFAULT_URL}/admin/delete/${id}`))
      .then((response) => {
        if (response.status === 200) {
          let updatedPolls: any = activePoll.map((poll: any) => {
            if(poll.id !== id) {
              return poll;
            }
          })
          setActivePoll(updatedPolls);
          setMessageState(`Deleted Poll ID: ${id} successfully!!`)
        }
      },(error) => {
        setMessageState(`Could not delete Poll ID: ${id}`)
      })
  };

  useEffect(() => {
    get(new URL(`${DEFAULT_URL}/admin/polls-metadata`))
      .then((response) => response.json(), 
      (error) => setMessageState('Error in fetching Data'))
      .then((data) =>{
        if(data.status !== 200){
          window.localStorage.removeItem("username");
          window.localStorage.removeItem("apikey");
          navigate('/login');
        }else{
          splitPollInfo(data);
        }
      } );
  }, []);

  const splitPollInfo = (data: any) => {
    const resultData = data && data.result;
    const currentDate = new Date();
    resultData.forEach((row: any) => {
      row.startDate= utcToIst(row.startDate);
      row.endDate = utcToIst(row.endDate);
      if (YYYYMMDDtoDate(row.endDate) > currentDate) {
        active.push(row);
      } else {
        expire.push(row);
      }
    });
    setActivePoll(active || []);
    setExpirePoll(expire || []);
  };

  const TableCellOne = (tableData: any) => {
    const { pannelValue, data } = tableData;
    return (
      <>
        <TabPanel value={pannelValue}>
          <TableCell align="center">Poll ID</TableCell>
          <TableCell align="center">Start Date</TableCell>
          <TableCell align="center">End Date</TableCell>
          {pannelValue === "2" ? (
            ""
          ) : (
            <TableCell align="right">Action</TableCell>
          )}
          <TableBody>
            {data && data.length && (
              <>
                {data
                  .filter((row: any) => {
                    return row && row.active;
                  })
                  .map((row: any) => (
                    <TableRow
                      key={row.id}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        { row &&
                        <Link to={`poll/${row.id}`}>{row.id}</Link>
                        }
                      </TableCell>
                      <TableCell align="right">{row.startDate}</TableCell>
                      <TableCell align="right">{row.endDate}</TableCell>
                      {pannelValue === "1" && (
                        <TableCell onClick={() => deletepoll(row.id)}>
                          <DeleteOutlinedIcon sx={{cursor: "pointer"}} />
                        </TableCell>
                      )}
                      {/* {pannelValue == "3" &&(
                    <TableCell>
                      <DraftsOutlinedIcon />
                    </TableCell>
                  )} */}
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </TabPanel>
      </>
    );
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Snackbar
        open={messageState ? true : false}
        autoHideDuration={6000}
        onClose={() => setMessageState(null)}
        message={`${messageState}`}
      />
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            fontSize: "40px",
            lineHeight: "32px",
            fontWeight: "700",
            color: "#48464A",
            marginLeft: "25px",
            marginTop: "10px",
          }}
        >
          Home
        </Typography>

        <TabContext value={activeTab}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              marginLeft: "30px",
              marginTop: "25px",
            }}
          >
            <TabList onChange={tabChange} aria-label="lab API tabs example">
              <Tab label="Active Poll" value="1" />
              <Tab label="Expired Poll" value="2" />
              {/* <Tab label="Draft" value="3" /> */}
            </TabList>
          </Box>
          <Box
            sx={{ marginLeft: "30px", marginTop: "25px", border: "divider" }}
          >
            <TableCellOne pannelValue="1" data={activePoll} />
            <TableCellOne pannelValue="2" data={expirePoll} />
            {/* <TableCellOne pannelValue="3" /> */}
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default Home;
