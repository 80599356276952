import { DEFAULT_URL } from "../../utils/Constants";
import { post } from "../../utils/FetchMethods";

export const postCall = (requestObj: any) => {
  return post(`${DEFAULT_URL}/admin/add`, requestObj)
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.error(error);
    });
};

export const deleteCall = (id: String) => {
  return fetch(`${DEFAULT_URL}/admin/delete/${id}`, {
    method: "DELETE",
  }).then((res: { json: () => any }) => {
    return res.json();
  });
};
