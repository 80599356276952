import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Snackbar,
  TextareaAutosize,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { defaultLanguageList } from "../../utils/TranslationInterface";
import { useNavigate } from "react-router-dom";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LangPopUp from "../../components/LangPopUp/LangPopUp";
import {
  getObjectProperty,
  getTranslationArray,
  setObjectProperty,
} from "../../utils/CommonUtils";
import { postCall } from "./PollService";
import { countryList } from "../../utils/Constants";

const defaultFormData = {
  question: defaultLanguageList,
  correctAnswer: null,
  option1: defaultLanguageList,
  option2: defaultLanguageList,
};

let defaultForm = [defaultFormData];

const defaultTextData = {
  contentid: "",
  gameid:"",
  tenantid:"",
  questionoption: "",
  responseoption: "",
  timer: "20",
};

const QuizPoll = () => {
  const [formData, setFormData] = useState(defaultForm);
  const [textData, setTextData] = useState(defaultTextData);
  const [startDate, setStartDateValue] = useState<Date | null>(new Date());
  const [endDate, setEndDateValue] = useState<Date | null>(new Date());
  const [startTimes, setStartTimes] = useState<Array<Date | null>>([new Date()]);
  const [endTimes, setEndTimes] = useState<Array<Date | null>>([new Date()]);
  const [country, setcountry] = useState("");
  const [popupState, setPopupState] = useState<String | null>(null);
  const [popupFlagState, setPopupFlagState] = useState<any[]>([false, false, false, false, false]);
  const [messageState, setMessageState] = useState<String | null>(null);

  const { contentid,tenantid, gameid, questionoption, responseoption, timer } = textData;
  const navigate = useNavigate();

  const reset = () => {
    setFormData(defaultForm);
    setTextData(defaultTextData);
    setStartDateValue(new Date());
    setEndDateValue(new Date());
  };

  const texthandleChange = (e: any) => {
    setTextData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    return null;
  };

  const openPopUp = (index: number, id: string) => {
    let popUp = popupFlagState;
    popUp[index] = true;
    setPopupFlagState(popUp);
    setPopupState(id);
  }
  const closePopUp = (index: number) => {
    let popUp = popupFlagState;
    popUp[index] = false;
    setPopupFlagState(popUp);
    setPopupState(null);
  };

  const submitLanguage = (id: string, language: any, index: number): any => {
    let data = formData[index];
    data = setObjectProperty(id, language, data);
    formData[index] = data;
    let newData = [...formData];
    setFormData(newData);
  };

  const onCountryChange = (event: any, values: any) => {
    setcountry(values);
  };

  const onOptionChange = (event: any, values: any, index: number) => {
    let data: any = formData[index];
    data = { ...data, correctAnswer: values };
    formData[index] = data;
    let newData = [...formData];
    setFormData(newData);
  };

  const addFields = (index: number) => {
    let currentFormTomutate: any = formData[index];
    let curLength = Object.keys(currentFormTomutate).length;
    if (curLength < 7) {
      let temp = `option${curLength - 1}`;
      currentFormTomutate[temp] = defaultLanguageList;
      formData[index] = currentFormTomutate;
      let newFormData = [...formData];
      setFormData(newFormData);
    }
  };

  const handleFormChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    let data: any = formData[index];
    data = {
      ...data,
      [e.target.id]: {
        ...getObjectProperty(e.target.id, data),
        en: e.target.value,
      },
    };
    formData[index] = data;
    let newData = [...formData];
    setFormData(newData);
  };

  const addQuestionFields = () => {
    if (formData.length < 500) {
      setFormData([
        ...formData,
        {
          question: defaultLanguageList,
          correctAnswer: null,
          option1: defaultLanguageList,
          option2: defaultLanguageList,
        },
      ]);
      setStartTimes([...startTimes, new Date()]);
      setEndTimes([...endTimes, new Date()]);
    }
  };

  const onSubmit = async () => {
    const requestObj: any = {
      assetId: contentid,
      adminId: "101",
      geo: country,
      gameId: gameid,
      tenantId: tenantid,
      category: "QUIZ",
      viewPollAudienceType: questionoption,
      viewResultAudienceType: responseoption,
      pollTimer: timer,
      startDate: startDate && startDate.toJSON(),
      endDate: endDate && endDate.toJSON(),
      pollQuestionsList: createpollQuestionsList(startTimes, endTimes),
    };
    const result = await postCall(requestObj);
    if (result && result.status === 200) {
      navigate("/");
    } else {
      setMessageState(result.message);
    }
  };

  const createpollQuestionsList = (  startTimes: Array<Date | null>,
    endTimes: Array<Date | null>
  ) => {
    const TempArray: any = [];
    formData.map((item, index) => {
      const data = {
        startTime: startTimes[index]?.toJSON(), 
        endTime: endTimes[index]?.toJSON(),
        originalContent: formData[index].question.en,
        originalLangCode: "en",
        questionTranslations: getTranslationArray(formData[index].question),
        options: Object.entries(formData[index])
          .slice(2)
          .map(([k, v]) => ({
            optionType: "TEXT",
            isCorrect: k === item?.correctAnswer,
            imageUrl: "",
            originalLangCode: "en",
            originalContent: v?.en,
            option_translation: getTranslationArray(v),
          })),
      };
      TempArray.push(data);
    });
    return TempArray;
  };
  const setStartDate = (newValue: Date | null) => {
    setStartDateValue(newValue);
  };
  const setEndDate = (newValue: Date | null) => {
    setEndDateValue(newValue);
  };

  const setStartTime = (newValue: Date | null, index: number) => {
    const newStartTimes = [...startTimes];
    newStartTimes[index] = newValue;
    setStartTimes(newStartTimes);
  };
  
  const setEndTime = (newValue: Date | null, index: number) => {
    const newEndTimes = [...endTimes];
    newEndTimes[index] = newValue;
    setEndTimes(newEndTimes);
  };
  
  return (
    <Box sx={{ display: "flex" }}>
      <Snackbar
        open={messageState ? true : false}
        autoHideDuration={6000}
        onClose={() => setMessageState(null)}
        message={`${messageState}`}
      />
      <Box>
        <Typography
          sx={{
            fontSize: "30px",
            lineHeight: "32px",
            fontWeight: "600",
            color: "#48464A",
            marginTop: "20px",
            marginLeft: "10px",
          }}
        >
          Quiz Poll
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            Select Country
          </Typography>
          <Autocomplete
            disablePortal
            id="country"
            options={countryList}
            onChange={onCountryChange}
            sx={{
              width: 300,
              bgcolormarginTop: "30px",
              marginLeft: "30px",
              marginTop: "15px",
            }}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            Content - Episode/Movie/video
          </Typography>
          <TextField
            sx={{ marginTop: "10px", marginLeft: "30px", width: "300px" }}
            id="contentid"
            label="Content ID"
            variant="outlined"
            value={contentid}
            onChange={texthandleChange}
          />
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            Tenant Id
          </Typography>
          <TextField
            sx={{ marginTop: "10px", marginLeft: "30px", width: "300px" }}
            id="tenantid"
            label="Tenant ID"
            variant="outlined"
            value={tenantid}
            onChange={texthandleChange}
          />
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            Game Id
          </Typography>
          <TextField
            sx={{ marginTop: "10px", marginLeft: "30px", width: "300px" }}
            id="gameid"
            label="Game ID"
            variant="outlined"
            value={gameid}
            onChange={texthandleChange}
          />
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "70px",
              marginLeft: "30px",
            }}
          >
            Poll Question access
          </Typography>
          <Box sx={{ marginLeft: "30px", marginTop: "15px" }}>
            <ToggleButtonGroup
              value={questionoption}
              exclusive
              onChange={texthandleChange}
              aria-label="text formatting"
              id="questionoption"
            >
              <ToggleButton value="GUEST" id="questionoption">
                Guest User
              </ToggleButton>
              <ToggleButton value="REGISTERED" id="questionoption">
                Registered User
              </ToggleButton>
              <ToggleButton value="PREMIUM" id="questionoption">
                Premium User
              </ToggleButton>
              <ToggleButton value="ALL" id="questionoption">
                All
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "70px",
              marginLeft: "30px",
            }}
          >
            Poll Response access
          </Typography>
          <Box sx={{ marginLeft: "30px", marginTop: "15px" }}>
            <ToggleButtonGroup
              exclusive
              value={responseoption}
              onChange={texthandleChange}
              aria-label="text formatting"
            >
              <ToggleButton value="GUEST" id="responseoption">
                Guest User
              </ToggleButton>
              <ToggleButton value="REGISTERED" id="responseoption">
                Registered User
              </ToggleButton>
              <ToggleButton value="PREMIUM" id="responseoption">
                Premium User
              </ToggleButton>
              <ToggleButton value="ALL" id="responseoption">
                All
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "70px",
              marginLeft: "30px",
            }}
          >
            Poll Duration
          </Typography>

          <Box sx={{ display: "flex", marginLeft: "30px", marginTop: "30px" }}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start Date"
                  value={startDate}
                  onChange={setStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginLeft: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End Date"
                  value={endDate}
                  onChange={setEndDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginTop: "25px", marginLeft: "30px" }}>
            <Typography>Poll Timer</Typography>
            <TextField
              label="sec"
              id="timer"
              onChange={texthandleChange}
              variant="outlined"
              value={textData.timer}
              sx={{ width: "100px", marginLeft: "30px", height: "30px" }}
            ></TextField>
          </Box>
        </Box>
        {formData.map((input, index) => {
          return (
            <Box>
              <Typography
                sx={{
                  fontSize: "20px",
                  lineHeight: "32px",
                  fontWeight: "600",
                  color: "#48464A",
                  marginTop: "70px",
                  marginLeft: "30px",
                }}
              >
                Quiz Question
              </Typography>
              <Box sx={{ display: "flex", marginLeft: "30px", marginTop: "30px" }}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start Time"
                  value={startTimes[index]}
                  onChange={(newValue) => setStartTime(newValue, index)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginLeft: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End Time"
                  value={endTimes[index]}
                  onChange={(newValue) => setEndTime(newValue, index)}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
              <Box sx={{ display: "flex", marginTop: "15px" }}>
                <TextareaAutosize
                  aria-label="minimum height"
                  minRows={3}
                  placeholder="Please enter the question"
                  id="question"
                  value={formData[index].question.en}
                  maxLength={250}
                  onChange={
                    formData[index].question.en.length <= 250
                      ? (e) => {
                          handleFormChange(e, index);
                        }
                      : undefined
                  }
                  style={{
                    width: "600px",
                    marginLeft: "30px",
                    resize: "none",
                    color: "#48464A",
                  }}
                />
                <Box
                  sx={{ marginTop: "15px", marginLeft: "10px" }}
                >{`${formData[index].question.en.length}/250`}</Box>
                <Button
                  onClick={() => openPopUp(index, "question")}
                  variant="text"
                >
                  Lang
                </Button>
              </Box>
              <LangPopUp
                popupValue={popupState}
                defaultData={formData[index]}
                index={index}
                closePopUp={closePopUp}
                saveLang={submitLanguage}
                popupOpenFlag={popupFlagState[index]}
              />
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "32px",
                      fontWeight: "600",
                      color: "#48464A",
                      marginTop: "70px",
                      marginLeft: "30px",
                    }}
                  >
                    Quiz Response
                  </Typography>
                  <Box sx={{ marginLeft: "30px" }}>
                    <form>
                      {Object.keys(formData[index])
                        .slice(2)
                        .map((input2, index2) => {
                          return (
                            <Box
                              key={index2}
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                marginTop: "15px",
                              }}
                            >
                              <TextareaAutosize
                                aria-label="minimum height"
                                minRows={3}
                                maxLength={150}
                                placeholder={`Option${index2 + 1}`}
                                id={`option${index2 + 1}`}
                                value={
                                  getObjectProperty(input2, formData[index])[
                                    "en"
                                  ]
                                }
                                onChange={
                                  getObjectProperty(input2, formData[index])[
                                    "en"
                                  ].length <= 150
                                    ? (e) => {
                                        handleFormChange(e, index);
                                      }
                                    : undefined
                                }
                                style={{
                                  width: "200px",
                                  resize: "none",
                                  color: "#48464A",
                                }}
                              />
                              <Box
                                sx={{ marginTop: "15px", marginLeft: "10px" }}
                              >{`${
                                getObjectProperty(input2, formData[index])["en"]
                                  .length
                              }/150`}</Box>
                              <Button
                                onClick={() => openPopUp(index, `${input2}`)}
                                variant="text"
                              >
                                Lang
                              </Button>
                            </Box>
                          );
                        })}
                    </form>
                    {Object.keys(formData[index]).length < 7 ? (
                      <Button
                        onClick={() => addFields(index)}
                        sx={{ marginTop: "15px" }}
                      >
                        add more
                      </Button>
                    ) : null}
                  </Box>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      fontSize: "20px",
                      lineHeight: "32px",
                      fontWeight: "600",
                      color: "#48464A",
                      marginTop: "70px",
                      marginLeft: "30px",
                    }}
                  >
                    Quiz Answer
                  </Typography>
                  <Autocomplete
                    disablePortal
                    options={Object.keys(formData[index]).slice(2)}
                    onChange={(e, values) => {
                      onOptionChange(e, values, index);
                    }}
                    sx={{
                      width: 300,
                      bgcolormarginTop: "30px",
                      marginLeft: "30px",
                      marginTop: "10px",
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select the Option" />
                    )}
                  />
                </Box>
              </Box>
            </Box>
          );
        })}
        <Box
          sx={{
            marginTop: "30px",
            marginBottom: "40px",
            marginLeft: "30px",
            display: "flex",
          }}
        >
          {formData.length < 500 ? (
            <Button
              size="large"
              variant="contained"
              onClick={addQuestionFields}
            >
              Add Question
            </Button>
          ) : null}
        </Box>
        <Box
          sx={{
            marginTop: "30px",
            marginBottom: "40px",
            marginLeft: "30px",
            display: "flex",
          }}
        >
          <Button size="large" variant="contained" onClick={onSubmit}>
            Submit
          </Button>
          <Box>
            <Button sx={{ marginLeft: "20px" }} onClick={reset}>
              Reset
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default QuizPoll;
