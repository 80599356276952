import { Box, LinearProgress, Typography } from "@mui/material";
import Card from "@mui/material/Card";
import { useEffect, useState } from "react";
import { useParams,useNavigate } from "react-router-dom";
import { DEFAULT_URL } from "../../utils/Constants";
import { get } from "../../utils/FetchMethods";

const PollInfo = () => {
  const [pollDetails, SetPollDetails] = useState<any>();
  const navigate = useNavigate();

  let { id } = useParams();

  useEffect(() => {
    get(`${DEFAULT_URL}/admin/opinion-poll-results/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if(data.status !== 200){
          window.localStorage.removeItem("username");
          window.localStorage.removeItem("apikey");
          navigate('/login');
        }else{
          SetPollDetails(data);
        }
      });
  }, []);
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {  (pollDetails &&
              pollDetails.result && pollDetails.result.options && pollDetails.result.options.length) ?
        (
          <>
        <Card
          variant="outlined"
          sx={{ maxWidth: "500px", marginTop: "150px", minWidth: "350px" }}
        >
          <LinearProgress
            sx={{ width: "350px" }}
            variant="determinate"
            value={
              pollDetails &&
              pollDetails.result && pollDetails.result.options && pollDetails.result.options.length &&
              pollDetails.result.options[0].aggPercentage
            }
          />
          <Typography
            sx={{
              fontSize: "30px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "20px",
              marginLeft: "10px",
              padding: "10px",
            }}
          >
            {`Opinion 1: ${
              pollDetails &&
              pollDetails.result && pollDetails.result.options && pollDetails.result.options.length &&
              pollDetails.result.options[0].aggPercentage
            }%`}
          </Typography>
        </Card>
        <Card
          variant="outlined"
          sx={{ maxWidth: "500px", marginTop: "50px", minWidth: "350px" }}
        >
          <LinearProgress
            sx={{ width: "350px" }}
            variant="determinate"
            value={
              pollDetails &&
              pollDetails.result && pollDetails.result.options && pollDetails.result.options.length &&
              pollDetails.result.options[1].aggPercentage
            }
          />
          <Typography
            sx={{
              fontSize: "30px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "20px",
              marginLeft: "10px",
              padding: "10px",
            }}
          >
            {`Opinion 2: ${
              pollDetails &&
              pollDetails.result && pollDetails.result.options && pollDetails.result.options.length &&
              pollDetails.result.options[1].aggPercentage
            }%`}
          </Typography>
        </Card>
        </>
        ) : (
          <Typography sx={{marginTop: "200px"}}>No Data Available</Typography>
        )
      }
      </Box>
    </>
  );
};
export default PollInfo;
