import {
  Box,
  Dialog,
  DialogContent,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { defaultLanguageList } from "../../utils/TranslationInterface";

const LangPopUp = (props: any) => {
  const [languageData, updatelanguageData] = useState(defaultLanguageList);

  const onSubmit = () => {
    props.saveLang(props.popupValue, languageData, props.index);
    props.closePopUp(props.index);
  };

  const textHandleChange = (e: any) => {
    updatelanguageData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    return null;
  };

  useEffect(() => {
    if (props.popupValue) {
      updatelanguageData(props.defaultData[props.popupValue]);
    }
  }, [JSON.stringify(props.popupValue)]);
  
  return (
    <Dialog
      open={props.popupOpenFlag} // condition for null check
      fullWidth={true}
      maxWidth={false}
    >
      <DialogContent>
        <Box>
          <>
            <Typography
              sx={{
                fontSize: "20px",
                lineHeight: "32px",
                fontWeight: "600",
                color: "#48464A",
                marginTop: "10px",
                marginLeft: "30px",
              }}
            >
              Add Translations
            </Typography>
            {languageData && Object.keys(languageData).map((language, index) => {
              let data = { ...languageData };
              type ObjectKey = keyof typeof data;
              const key = language as ObjectKey;
              return (
                <TextField
                  key={index}
                  label={`${language}`}
                  id={`${language}`}
                  disabled={Object.keys(languageData)[index] === "en"}
                  onChange={textHandleChange}
                  variant="outlined"
                  sx={{ width: "600px", marginLeft: "30px", marginTop: "15px" }}
                  value={languageData[key]}
                ></TextField>
              );
            })}
            <Box sx={{ marginTop: "15px" }}>
              <Button sx={{ marginLeft: "30px" }} onClick={onSubmit}>
                Submit
              </Button>
              <Button onClick={() => props.closePopUp(props.index)}>Close</Button>
            </Box>
          </>
        </Box>
      </DialogContent>
    </Dialog>
  );
};
export default LangPopUp;
