export const post = (url: any, requestObj: any) => {
  const username = window.localStorage.getItem('username')||"";
  const password = window.localStorage.getItem('apikey')||"";
  return fetch(url, {
    method: "POST",
    body: JSON.stringify(requestObj),
    headers: {
      'host': 'qa-polling.zee5.qa',
      'Content-Type': 'application/json',
      'apiKey': password,
      'userName': username
    },
  })
};

export const get = (url: any) => {
  const username = window.localStorage.getItem('username')||"";
  const password = window.localStorage.getItem('apikey')||"";
  return fetch(url, {
    method: "GET",
    headers: {
      'host': 'qa-polling.zee5.qa',
      'apiKey': password,
      'userName': username
    },
  })
};

export const deleteCall = (url: any) => {
  const username = window.localStorage.getItem('username')||"";
  const password = window.localStorage.getItem('apikey')||"";
  return fetch(url, {
  method: 'DELETE',
  headers: {
    'host': 'qa-polling.zee5.qa',
    'apiKey': password,
    'userName': username
  },
})
};
