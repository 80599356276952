import {useState} from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import QuizPoll from "./pages/Poll/QuizPoll";
import OpinionPoll from "./pages/Poll/OpinionPoll";
import PollInfo from "./pages/PollInfo/PollInfo";
import Home from "./pages/Home/Home";
import Login from "./pages/login/Login";
import App from "./App";
import Protected from "./Protected";

export default function PollPages() {
    const [isLoggedIn, setisLoggedIn] = useState<boolean>(false);
    

    return (<>
        <BrowserRouter>
            <Routes>
            <Route path="/" element={<App />}>
                <Route path="/" element={<Protected isLoggedIn={isLoggedIn}>
                                    <Home />
                                </Protected>}></Route>
                <Route path="quizpoll" element={<Protected isLoggedIn={isLoggedIn}>
                                <QuizPoll />
                                </Protected>}></Route>
                <Route path="opinionpoll" 
                       element={<Protected isLoggedIn={isLoggedIn}>
                                <OpinionPoll />
                                </Protected>}>
                </Route>
                <Route path="poll/:id" element={<Protected isLoggedIn={isLoggedIn}>
                                <PollInfo />
                                </Protected>} />
                <Route path="/login" element={<Login setisLoggedIn={setisLoggedIn} />} />
            </Route>
            </Routes>
        </BrowserRouter>
    </>)

}