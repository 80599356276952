import React, { useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  TextareaAutosize,
  TextField,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import LangPopUp from "../../components/LangPopUp/LangPopUp";
import { defaultLanguageList } from "../../utils/TranslationInterface";
import {
  getObjectProperty,
  getTranslationArray,
  setObjectProperty,
} from "../../utils/CommonUtils";
import { postCall } from "./PollService";
import { useNavigate } from "react-router-dom";
import { countryList } from "../../utils/Constants";

const defaultFormData = {
  question: defaultLanguageList,
  option1: defaultLanguageList,
  option2: defaultLanguageList,
};
const defaultTextData = {
  contentid: "",
  questionoption: "",
  responseoption: "",
  timer: "20",
};

const OpinionPoll = () => {
  const [formData, setFormData] = useState(defaultFormData);
  const [textData, setTextData] = useState(defaultTextData);
  const [country, setcountry] = useState("");
  const [startDate, setStartDateValue] = useState<Date | null>(new Date());
  const [endDate, setEndDateValue] = useState<Date | null>(new Date());
  const [popupState, setPopupState] = useState<String | null>(null);
  const [messageState, setMessageState] = useState<String | null>(null);
  const [popupOpenFlag, setPopupOpenFlag] = useState<boolean>(false)

  const { question, option1, option2 } = formData;
  const { contentid, questionoption, responseoption, timer } = textData;
  const navigate = useNavigate();
  const reset = () => {
    setFormData(defaultFormData);
    setTextData(defaultTextData);
    setStartDateValue(new Date());
    setEndDateValue(new Date());
    setcountry("");
  };
  

  const submitForm = async () => {
    const requestObj = {
      assetId: contentid,
      adminId: "101",
      geo: country,
      category: "OPINION",
      viewPollAudienceType: questionoption,
      viewResultAudienceType: responseoption,
      pollTimer: timer,
      startDate: startDate && startDate.toJSON(),
      endDate: endDate && endDate.toJSON(),
      pollQuestionsList: [
        {
          originalContent: formData.question.en,
          originalLangCode: "en",
          questionTranslations: getTranslationArray(formData.question),
          options: Object.values(formData)
            .slice(1)
            .map((input) => ({
              optionType: "TEXT",
              imageUrl: "",
              originalLangCode: "en",
              originalContent: input.en,
              option_translation: getTranslationArray(input),
            })),
        },
      ],
    };
    const result = await postCall(requestObj);
    if (result && result.status === 200) {
      navigate("/");
    } else {
      setMessageState(result.message);
    }
  };

  const closePopUp = () => {
    setPopupOpenFlag(false);
    setPopupState(null);
  };

  //submit method

  const submitLanguage = (id: string, language: any): any => {
    let data = { ...formData };
    data = setObjectProperty(id, language, data);
    setFormData({ ...data });
  };

  // onchange methods

  const updateNestedProps = (e: any) => {
    setFormData((formData) => {
      return {
        ...formData,
        [e.target.id]: {
          ...getObjectProperty(e.target.id, formData),
          en: e.target.value,
        },
      };
    });
  };
  console.log(formData)

  const textHandleChange = (e: any) => {
    setTextData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    return null;
  };
  const LangpopupOpenHandler = (value: string) => {
    setPopupState(value)
    setPopupOpenFlag(true);
  }
  const setStartDate = (newValue: Date | null) => {
    setStartDateValue(newValue);
  };

  const setEndDate = (newValue: Date | null) => {
    setEndDateValue(newValue);
  };

  const onCountryChange = (event: any, values: any) => {
    setcountry(values);
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Snackbar
        open={messageState ? true : false}
        autoHideDuration={6000}
        onClose={() => setMessageState(null)}
        message={`${messageState}`}
      />
      <Box>
        <Typography
          sx={{
            fontSize: "30px",
            lineHeight: "32px",
            fontWeight: "600",
            color: "#48464A",
            marginTop: "20px",
            marginLeft: "10px",
          }}
        >
          Opinion Poll
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            Select Country
          </Typography>
          <Autocomplete
            disablePortal
            id="country"
            options={countryList}
            onChange={onCountryChange}
            sx={{
              width: 300,
              bgcolormarginTop: "30px",
              marginLeft: "30px",
              marginTop: "15px",
            }}
            renderInput={(params) => <TextField {...params} label="Country" />}
          />
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "30px",
              marginLeft: "30px",
            }}
          >
            Content - Episode/Movie/video
          </Typography>
          <TextField
            sx={{ marginTop: "10px", marginLeft: "30px", width: "300px" }}
            id="contentid"
            label="Content ID"
            variant="outlined"
            value={contentid}
            onChange={textHandleChange}
          />
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "70px",
              marginLeft: "30px",
            }}
          >
            Poll Question access
          </Typography>
          <Box sx={{ marginLeft: "30px", marginTop: "15px" }}>
            <ToggleButtonGroup
              value={questionoption}
              exclusive
              onChange={textHandleChange}
              aria-label="text formatting"
              id="questionoption"
            >
              <ToggleButton value="GUEST" id="questionoption">
                Guest User
              </ToggleButton>
              <ToggleButton value="REGISTERED" id="questionoption">
                Registered User
              </ToggleButton>
              <ToggleButton value="PREMIUM" id="questionoption">
                Premium User
              </ToggleButton>
              <ToggleButton value="ALL" id="questionoption">
                All
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "70px",
              marginLeft: "30px",
            }}
          >
            Poll Response access
          </Typography>
          <Box sx={{ marginLeft: "30px", marginTop: "15px" }}>
            <ToggleButtonGroup
              exclusive
              value={responseoption}
              onChange={textHandleChange}
              aria-label="text formatting"
            >
              <ToggleButton value="GUEST" id="responseoption">
                Guest User
              </ToggleButton>
              <ToggleButton value="REGISTERED" id="responseoption">
                Registered User
              </ToggleButton>
              <ToggleButton value="PREMIUM" id="responseoption">
                Premium User
              </ToggleButton>
              <ToggleButton value="ALL" id="responseoption">
                All
              </ToggleButton>
            </ToggleButtonGroup>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              lineHeight: "32px",
              fontWeight: "600",
              color: "#48464A",
              marginTop: "70px",
              marginLeft: "30px",
            }}
          >
            Poll Duration
          </Typography>
          <Box sx={{ display: "flex", marginLeft: "30px", marginTop: "30px" }}>
            <Box>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="Start Date"
                  value={startDate}
                  onChange={setStartDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
            <Box sx={{ marginLeft: "15px" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  label="End Date"
                  value={endDate}
                  onChange={setEndDate}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginTop: "25px", marginLeft: "30px" }}>
            <Typography>Poll Timer</Typography>
            <TextField
              label="sec"
              id="timer"
              onChange={textHandleChange}
              variant="outlined"
              sx={{ width: "100px", marginLeft: "30px", height: "30px" }}
            ></TextField>
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "32px",
            fontWeight: "600",
            color: "#48464A",
            marginTop: "70px",
            marginLeft: "30px",
          }}
        >
          Poll Question
        </Typography>
        <Box sx={{ display: "flex", marginTop: "25px" }}>
          <TextareaAutosize
            aria-label="minimum height"
            minRows={3}
            placeholder="Please enter the question"
            maxLength={250}
            id="question"
            value={formData.question.en}
            onChange={question.en.length <= 250 ? updateNestedProps : undefined}
            style={{
              width: "600px",
              marginLeft: "30px",
              resize: "none",
              color: "#48464A",
            }}
          />
          <Box
            sx={{ marginTop: "20px", marginLeft: "10px" }}
          >{`${question.en.length}/250`}</Box>
          <Button onClick={() => LangpopupOpenHandler("question")} variant="text">
            Lang
          </Button>
        </Box>
        <LangPopUp
          popupValue={popupState}
          defaultData={formData}
          closePopUp={closePopUp}
          saveLang={submitLanguage}
          popupOpenFlag={popupOpenFlag}
        />
        <Typography
          sx={{
            fontSize: "20px",
            lineHeight: "32px",
            fontWeight: "600",
            color: "#48464A",
            marginTop: "70px",
            marginLeft: "30px",
          }}
        >
          Poll Response
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Box sx={{ display: "flex", marginTop: "25px" }}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              maxLength={150}
              placeholder="Option 1"
              id="option1"
              value={formData.option1.en}
              onChange={
                option1.en.length <= 150 ? updateNestedProps : undefined
              }
              style={{
                width: "200px",
                marginLeft: "30px",
                resize: "none",
                color: "#48464A",
              }}
            />
            <Box sx={{ marginTop: "15px", marginLeft: "10px" }}>
              {`${option1.en.length}/150`}
              <Button onClick={() => LangpopupOpenHandler("option1")} variant="text">
                Lang
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", marginTop: "25px" }}>
            <TextareaAutosize
              aria-label="minimum height"
              minRows={3}
              placeholder="Option 2"
              maxLength={150}
              id="option2"
              value={formData.option2.en}
              onChange={
                option1.en.length <= 150 ? updateNestedProps : undefined
              }
              style={{
                width: "200px",
                marginLeft: "30px",
                resize: "none",
                color: "#48464A",
              }}
            />
            <Box sx={{ marginTop: "20px", marginLeft: "10px" }}>
              {`${option2.en.length}/150`}
              <Button onClick={() => LangpopupOpenHandler("option2")} variant="text">
                Lang
              </Button>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ marginTop: "30px", marginBottom: "40px", marginLeft: "30px" }}
        >
          <Button variant="contained" onClick={submitForm}>
            Submit
          </Button>
          <Button sx={{ marginLeft: "20px" }} onClick={reset}>
            Reset
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default OpinionPoll;
