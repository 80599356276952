import { Drawer, List, ListItemButton, ListItemText } from "@mui/material";
import { useNavigate } from "react-router-dom";

const drawerWidth = 300;
const SideDrawer = () => {
  const navigate = useNavigate();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <List>
        <ListItemButton onClick={() => navigate("/")}>
          <ListItemText primary="Home" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/opinionpoll")}>
          <ListItemText primary="Polling-Opinion" />
        </ListItemButton>
        <ListItemButton onClick={() => navigate("/quizpoll")}>
          <ListItemText primary="Polling-Quiz" />
        </ListItemButton>
      </List>
    </Drawer>
  );
};

export default SideDrawer;
