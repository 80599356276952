import { useNavigate } from "react-router-dom";
import { Box, TextField, Button, Typography } from '@mui/material';
import { useState } from "react";

export default function Login({setisLoggedIn}: any){
    const navigate = useNavigate();
    const [username,setUserName] = useState<string>("");
    const [apikey,setApiKey] = useState<string>("");

    const setUsernameChange = (e: any) => {
        setUserName(e.target.value);
    }

    const setPasswordChange = (e: any) => {
      setApiKey(e.target.value);
    }

    const loginVerify = () => {
            window.localStorage.setItem('username', username);
            window.localStorage.setItem('apikey', apikey);
            setisLoggedIn(true);
            navigate('/');
    }

    return <div style={{'marginLeft': '35vw', 'marginTop': '28vh'}}>
         <Box
      sx={{
        width: 400,
        height: 300,
        border: '2px dashed grey'
      }}
    >
        <Typography
          sx={{
            fontSize: "30px",
            lineHeight: "32px",
            fontWeight: "500",
            color: "#48464A",
            marginLeft: "35%",
            marginTop: "25px",
          }}
        >
          SIGN IN
        </Typography>
                <TextField
                  label={`Username`}
                  variant="outlined"
                  onChange={setUsernameChange}
                  value={username}
                  sx={{ width: "300px", marginLeft: "30px", marginTop: "15px" }}
                ></TextField>
                <TextField
                  label={`Password`}
                  variant="outlined"
                  onChange={setPasswordChange}
                  value={apikey}
                  type={"password"}
                  sx={{ width: "300px", marginLeft: "30px", marginTop: "15px" }}
                ></TextField>
                <Button onClick={() => { loginVerify()}} sx={{ marginLeft: "30px",marginTop: '10px' }}>
                Login
              </Button>
    </Box>
    </div>
}